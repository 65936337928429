import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair, IPropertyOverride, IPreviousDateRangeDisplay } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' },
    { name: 'Trend', value: 'Trend' }
  ];

  public useDefaultComparisonColumns = true;

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'bestMatchSales',
      overrideProperty: 'sameDealerBestMatchSales'
    },
    {
      baseProperty: 'bestMatchCloseRate',
      overrideProperty: 'sameDealerBestMatchCloseRate'
    },
    {
      baseProperty: 'bestMatchCloseRate30',
      overrideProperty: 'sameDealerBestMatchCloseRate30'
    },
    {
      baseProperty: 'bestMatchCloseRate60',
      overrideProperty: 'sameDealerBestMatchCloseRate60'
    },
    {
      baseProperty: 'bestMatchCloseRate90',
      overrideProperty: 'sameDealerBestMatchCloseRate90'
    },
    {
      baseProperty: 'bestMatchCloseRate120',
      overrideProperty: 'sameDealerBestMatchCloseRate120'
    },
  ];

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Total Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.uniqueLeads.metricId,
        displayName: 'Unique Lead Volume',
        propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },

      {
        id: Enums.leadMetrics.responseRate.metricId,
        displayName: Enums.leadMetrics.responseRate.name,
        propertyName: Enums.leadMetrics.responseRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.responseRate30.metricId,
        displayName: 'Response Rate < 30 Min',
        propertyName: Enums.leadMetrics.responseRate30.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.averageResponseTime.metricId,
        displayName: Enums.leadMetrics.averageResponseTime.name,
        propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeString
      },
      //{
      //  id: Enums.leadMetrics.leadSales.metricId,
      //  displayName: Enums.leadMetrics.leadSales.name,
      //  propertyName: 'bestMatchSales',
      //  metricFormatKey: constants.formatKeys.localeString
      //},
      // Lost Sales
      {
        id: Enums.leadMetrics.lostDealerSales.metricId,
        displayName: 'Lost Opportunities',
        propertyName: 'lostDealerSales',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Appointment Rate
      {
        id: Enums.leadMetrics.appointmentRate.metricId,
        displayName: Enums.leadMetrics.appointmentRate.name,
        propertyName: Enums.leadMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // BestMatchSales
      {
        id: Enums.leadMetrics.bestMatchSales.metricId,
        displayName: 'Lead Sales',
        propertyName: Enums.leadMetrics.bestMatchSales.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.closeRate.metricId,
        displayName: Enums.leadMetrics.closeRate.name,
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.closeRate30.metricId,
        displayName: Enums.leadMetrics.closeRate30.name,
        propertyName: 'bestMatchCloseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.closeRate60.metricId,
        displayName: Enums.leadMetrics.closeRate60.name,
        propertyName: 'bestMatchCloseRate60',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.closeRate90.metricId,
        displayName: Enums.leadMetrics.closeRate90.name,
        propertyName: 'bestMatchCloseRate90',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.closeRate120.metricId,
        displayName: Enums.leadMetrics.closeRate120.name,
        propertyName: 'bestMatchCloseRate120',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.closeRate.metricId,
    metricOneColorHexCode: '#243882',
    metricTwoColorHexCode: '#292b33'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName
    },
    // Total Leads
    {
      show: true,
      header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Unique Leads
    {
      show: true, header: 'Total Unique leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Response Rate
    {
      show: true,
      header: 'Response Rate',
      columnDef: 'responseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Response Rate < 30 min
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Overall Average Response Time
    {
      show: true,
      header: 'Overall Average Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString
    },
    //// Lead Sales
    //{
    //  show: true,
    //  header: 'Lead Sales',
    //  columnDef: 'bestMatchSales',
    //  hasComparisonMetrics: true,
    //  metricFormatKey: constants.formatKeys.localeString
    //},
    // Lost Sales
    {
      show: true,
      header: 'Lost Opportunities',
      columnDef: 'lostDealerSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Lead Sales
    {
      show: true,
      header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 30 Days
    {
      show: true,
      header: '30 Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 60 Days
    {
      show: true,
      header: '60 Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 90 Days
    {
      show: true,
      header: '90 Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 120 Days
    {
      show: true,
      header: '120 Day Close Rate',
      columnDef: 'bestMatchCloseRate120',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
  ];

  public trendTableMetrics: INameValuePair[] = [
    { name: 'Total Leads', value: 'leadVolume' },
    { name: 'Unique Leads', value: 'uniqueLeads'},
    { name: 'Response Rate', value: 'responseRate' },
    { name: 'Response Rate < 30 Min', value: 'responseRate30' },
    { name: 'Overall Average Response Time', value: 'averageResponseTime' },
    { name: 'Lead Sales', value: 'bestMatchSales' },
    { name: 'Lost Opportunities', value: 'lostDealerSales' },
    { name: 'Appointment Rate', value: 'appointmentRate' },
    { name: 'Close Rate', value: 'bestMatchCloseRate' },
    { name: '30 Day Close Rate', value: 'bestMatchCloseRate30' },
    { name: '60 Day Close Rate', value: 'bestMatchCloseRate60' },
    { name: '90 Day Close Rate', value: 'bestMatchCloseRate90' },
    { name: '120 Day Close Rate', value: 'bestMatchCloseRate120' },
  ];
}
