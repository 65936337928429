import {
  ILeadsOverviewBillboardConfig,
  ILeadsOverviewConfig,
  ILeadsOverviewDealerKpiConfig,
  IDefaultTableColumnConfig,
  ILeadsOverviewKpiConfig,
  ILeadsOverviewSourceTypeCardConfig,
  IPropertyOverride
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class LeadsOverviewConfig implements ILeadsOverviewConfig {
  public reportTitle = 'Leads Overview';
  useDefaultComparisonColumns: boolean = true;
  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'bestMatchSales',
      overrideProperty: 'sameDealerBestMatchSales'
    },
    {
      baseProperty: 'bestMatchCloseRate',
      overrideProperty: 'sameDealerBestMatchCloseRate'
    },
    {
      baseProperty: 'bestMatchCloseRate30',
      overrideProperty: 'sameDealerBestMatchCloseRate30'
    },
    {
      baseProperty: 'bestMatchCloseRate60',
      overrideProperty: 'sameDealerBestMatchCloseRate60'
    },
    {
      baseProperty: 'bestMatchCloseRate90',
      overrideProperty: 'sameDealerBestMatchCloseRate90'
    },
  ];

  public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = {
    showComparisonMetrics: true,
    leadMetric: {
      name: 'Unique Leads',
      value: 'uniqueLeads'
    },
    closeRateMetric: {
      name: 'Rolling Close Rate',
      value: 'bestMatchCloseRate'
    }
  }

  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];


  public billboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-user fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Responded < 30 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate30',
      metricMOMPropertyName: 'responseRate30MOM',
      metricYOYPropertyName: 'responseRate30YOY',
      metricPreviousMonthPropertyName: 'responseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate30PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate30MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewRespondedLessThan30Help',
      helpTextTitle: '% Responded < 30 Min'
    },
    {
      title: 'Appointment Rate',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousYearPropertyName: 'appointmentRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Close Rate'
    },
  ];

  public dealerbillboards: ILeadsOverviewBillboardConfig[] = [

    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-user fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Responded < 30 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate30',
      metricMOMPropertyName: 'responseRate30MOM',
      metricYOYPropertyName: 'responseRate30YOY',
      metricPreviousMonthPropertyName: 'responseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate30PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate30MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewRespondedBillboardHelp',
      helpTextTitle: '% Responded < 30 Min'
    },
    {
      title: 'Appointment Rate',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousYearPropertyName: 'appointmentRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'POP',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Close Rate'
    },
  ];

  public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = {
    metrics: {
      'CloseRate': {
        yAxisTitle: 'Close Rate %',
        displayName: 'Close Rate %',
        pointLabelFormat: null,
        propertyName: 'sameDealerBestMatchCloseRate',
        metricFormatKey: null,
        formatter: null
      },
      'LeadVolume': {
        yAxisTitle: 'Lead Volume',
        displayName: 'Lead Volume',
        pointLabelFormat: null,
        propertyName: 'leadVolume',
        metricFormatKey: null,
        formatter: null
      },
      'ResponseRate': {
        yAxisTitle: '% Responded < 1 Hr',
        displayName: '% Responded < 1 Hr',
        pointLabelFormat: null,
        propertyName: 'responseRate60',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
  public kpiCardConfig: ILeadsOverviewKpiConfig = {
    metrics: {
      'ResponseRate': {
        yAxisTitle: '% Responded < 30 Min',
        displayName: 'Response Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 100)
      },
      'CloseRate': {
        yAxisTitle: 'Close Rate',
        displayName: 'Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 10000) / 100
      }
    }
  };
  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Unique Leads
    {
      show: true, header: 'Total Unique leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Response Rate
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Average Response Time
    {
      show: true,
      header: 'Avg. Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Closed Leads
    {
      show: true,
      header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    //30 Day  Close Rate
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
  ];

  public dealerBenchmarkLookbackColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Unique Leads
    {
      show: true, header: 'Total Unique leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Response Rate
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Average Response Time
    {
      show: true,
      header: 'Avg. Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      clickable: false
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Lead Sales
    {
      show: true,
      header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    //30 Day  Close Rate
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
  ];
}
