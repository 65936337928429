

import {
    IWebsiteOverviewBillboardConfig,
    IWebsiteOverviewConfig,
    IDefaultTableColumnConfig,
    IWebsiteOverviewKpiConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteOverviewConfig implements IWebsiteOverviewConfig {
    public reportTitle = 'Website Overview';
    public benchmarkTitle = 'Retailer Performance';
    public useNewMetricCutoffDate = true;
    public useDefaultComparisonColumns = true;
    public newMetricCutoffDate = new Date(2019, 0, 1);
    public showPieChart: boolean = true;
    public chartSeriesColors = ['#4c4c4c', '#C3002F', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
    public deviceTypeColors = ['#4c4c4c', '#C3002F', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
    public referrerTypeColors = ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
    public referrerQualityColors = ['#18b52d', '#224197', '#f9bb11', '#c51230'];
    public referrerQualityCardHeader: ICardHeaderConfig = {
      title: 'Referrer Quality',
      iconClass: 'fa-star',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
      helpTextTitle: 'Referrer Quality',
    };
    public referrerTypeCardHeader: ICardHeaderConfig = {
      title: 'Referrer Type',
      iconClass: 'fa-align-left',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
      helpTextTitle: 'Referrer Type',
    };
    public trafficByDeviceTypeHeader: ICardHeaderConfig = {
      title: 'Traffic By Device',
      iconClass: 'fa-mobile',
      helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
      helpTextTitle: 'Traffic By Device',
    };
    public visitorTrendCardHeader: ICardHeaderConfig = {
      title: 'Visitor Trend',
      iconClass: 'fa-bar-chart',
      helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
      helpTextTitle: 'Visitor Trend',
    };
    public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Retailer Performance',
      iconClass: 'fa-suitcase',
      helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
      helpTextTitle: 'Benchmark',
      exportName: 'Website Overview - Retailer Performance',
      metricDisplayModes: ['MOM', 'YOY']
    };
    public billboards: IWebsiteOverviewBillboardConfig[] = [
        {
            title: 'Visits',
            subtitle: '',
            iconClass: 'fa-users fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalVisits',
            metricMOMPropertyName: 'totalVisitsMOM',
            metricYOYPropertyName: 'totalVisitsYOY',
            metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
            metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'totalVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
            helpTextTitle: 'Visits',
            showTrendArrow: true
        },
        {
            title: 'Unique Visitors',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'uniqueVisits',
            metricMOMPropertyName: 'uniqueVisitsMOM',
            metricYOYPropertyName: 'uniqueVisitsYOY',
            metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
            metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewUniqueVisitsBillboardHelp,
            helpTextTitle: 'Unique Visitor',
            showTrendArrow: true
        },
        {
            title: 'Engagement',
            subtitle: '',
            iconClass: 'fa-user-plus fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'engagements',
            metricMOMPropertyName: 'engagementsMOM',
            metricYOYPropertyName: 'engagementsYOY',
            metricPreviousMonthPropertyName: 'engagementsPreviousMonth',
            metricPreviousMTDPropertyName: 'engagementsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'engagementsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewEngagementsBillboardHelp,
            helpTextTitle: 'Engagement',
            showTrendArrow: true
        },
        {
            title: 'Actions',
            subtitle: '',
            iconClass: 'fa-phone fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'actions',
            metricMOMPropertyName: 'actionsMOM',
            metricYOYPropertyName: 'actionsYOY',
            metricPreviousMonthPropertyName: 'actionsPreviousMonth',
            metricPreviousMTDPropertyName: 'actionsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'actionsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewActionsBillboardHelp,
            helpTextTitle: 'Actions',
            showTrendArrow: true
        },
    ];

    public kpiSheetOptions = {
      overview: [{
        name: 'name',
        label: '',
        isHeader: true
      }, {
        name: 'visits',
        label: 'Visits',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'vdps',
        label: 'VDPs',
        format: constants.formatKeys.localeString
      }, {
        name: 'engagementRate',
        label: 'Engagement Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'bounceRate',
        label: 'Bounce Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'salesServiceOtherBounces',
        label: 'Sales | Service | Other | Bounces',
      }]
    };

    public kpiCardConfig: IWebsiteOverviewKpiConfig = {
      chartTitle: 'Total Visits vs. Engaged Visits',
      preCutoffChartTitle: 'Total Visits vs. Unique Visits',
      xAxisPropertyName: 'date',
      metrics: {
        'MetricOne': {
            yAxisTitle: 'Visits',
            displayName: 'Total Visits',
            pointLabelFormat: null,
            propertyName: 'totalVisits',
            metricFormatKey: null,
            formatter: null
        },
        'MetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Engaged Visits',
            pointLabelFormat: null,
            propertyName: 'visitsWithEngagements',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Unique Visits',
            pointLabelFormat: null,
            propertyName: 'uniqueVisits',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'totalVisits',
          metricFormatKey: null,
          formatter: null
        }
      }
    };

    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
        {
          show: true,
          header: '',
          columnDef: 'entity',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
        },
        {
          show: false,
          print: true,
          header: 'Retailer Name',
          columnDef: 'displayName',
          metricFormatKey: constants.formatKeys.entityDisplayName, 
          clickable: false
        },
        // Visits
        {
          show: true,
          header: 'Visits',
          columnDef: 'totalVisits',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Qualified Visits
        {
          show: true,
          header: 'Qualified Visits',
          columnDef: 'qualifiedVisits',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Page Views
        {
          show: true,
          header: 'Page Views',
          columnDef: 'pageViews',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // New VDPs
        {
          show: true,
          header: 'New VDPs',
          columnDef: 'newInventoryDetailViews',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Used VDPs
        {
          show: true,
          header: 'Used VDPs',
          columnDef: 'usedInventoryDetailViews',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Used VDPs
        {
          show: true,
          header: 'CPO VDPs',
          columnDef: 'cpoInventoryDetailViews',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Service Views
        {
          show: true,
          header: 'Service Views',
          columnDef: 'servicePageViews',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Engagement Rate
        {
          show: true,
          header: 'Engagement Rate',
          columnDef: 'engagementRate',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Action Rate
        {
          show: true,
          header: 'Action Rate',
          columnDef: 'actionRate',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Avg Time On Site
        {
          show: true,
          header: 'Time On Site',
          columnDef: 'averageTimeOnSite',
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Bounces
        {
          show: true,
          header: 'Bounces',
          columnDef: 'bounces',
          metricFormatKey: constants.formatKeys.localeString,
          clickable: false,
          hasComparisonMetrics: true
        },
        // Bounce Rate
        {
          show: true,
          header: 'Bounce Rate',
          columnDef: 'bounceRate',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          clickable: false,
          hasComparisonMetrics: true
        }
    ];
}
