
import {
  ISalesOverviewConfig,
  ISalesOverviewBillboardConfig,
  IDefaultTableColumnConfig,
  ISalesOverviewKpiConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class SalesOverviewConfig implements ISalesOverviewConfig {
  public reportTitle = 'Sales Overview';
  public chartSeriesColors = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public billboards: ISalesOverviewBillboardConfig[] = [
    {
      title: 'Total Sales',
      subtitle: null,
      subtitleOne: 'totalSalesPreviousMTD',
      subtitleTwo: 'totalSalesPreviousMonth',
      iconClass: 'fa-car fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'totalSales',
      metricMOMPropertyName: 'totalSalesMOM',
      metricYOYPropertyName: 'totalSalesYOY',
      metricPreviousMonthPropertyName: 'totalSalesPreviousMonth',
      metricPreviousMTDPropertyName: 'totalSalesPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'totalSalesMOM',
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: 'SalesOverviewTotalSalesHelp',
      helpTextTitle: 'Total Sales',
      showTrendArrow: true
    },
    {
      title: 'Sales From Leads',
      subtitle: null,
      subtitleOne: 'leadSalesPreviousMTD',
      subtitleTwo: 'leadSalesPreviousMonth',
      iconClass: 'fa-user fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadSales',
      metricMOMPropertyName: 'leadSalesMOM',
      metricYOYPropertyName: 'leadSalesYOY',
      metricPreviousMonthPropertyName: 'leadSalesPreviousMonth',
      metricPreviousMTDPropertyName: 'leadSalesPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'leadSalesMOM',
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: 'SalesOverviewLeadSalesHelp',
      helpTextTitle: 'Sales From Leads',
      showTrendArrow: true
    },
    {
      title: 'Lead Contr. Rate',
      subtitle: null,
      subtitleOne: 'leadContributionPreviousMTD',
      subtitleTwo: 'leadContributionPreviousMonth',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadContribution',
      metricMOMPropertyName: 'leadContributionMOM',
      metricYOYPropertyName: 'leadContributionYOY',
      metricPreviousMonthPropertyName: 'leadContributionPreviousMonth',
      metricPreviousMTDPropertyName: 'leadContributionPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'leadContributionMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'SalesOverviewLeadContributionRateHelp',
      helpTextTitle: 'Lead Contribution Rate',
      showTrendArrow: true
    },
    {
      title: 'Dealer Avg. Lost Sales',
      subtitle: null,
      subtitleOne: 'lostSalesDealerAveragePreviousMTD',
      subtitleTwo: 'lostSalesDealerAveragePreviousMonth',
      iconClass: 'fa-user fa-primary',
      reverseMetric: true,
      metricCurrentPropertyName: 'lostSalesDealerAverage',
      metricMOMPropertyName: 'lostSalesDealerAverageMOM',
      metricYOYPropertyName: 'lostSalesDealerAverageYOY',
      metricPreviousMonthPropertyName: 'lostSalesDealerAveragePreviousMonth',
      metricPreviousMTDPropertyName: 'lostSalesDealerAveragePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'lostSalesDealerAverageMOM',
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: 'SalesOverviewDealerAvgLostSalesBillboardHelp',
      helpTextTitle: 'Dealer Avg. Lost Sales',
      showTrendArrow: true
    },
  ];
  // jmstodo: not so sure about this for these charts - specific model for area chart probably better
  public kpiDailyCardConfig: ISalesOverviewKpiConfig = {
    chartTitle: null,
    xAxisPropertyName: 'date',
    metrics: [
      {
        yAxisTitle: 'Sales',
        displayName: 'New Sales',
        pointLabelFormat: null,
        propertyName: 'sales',
        saleTypeId: 1,
        metricFormatKey: null,
        formatter: null
      },
      {
        yAxisTitle: 'Sales',
        displayName: 'CPO Sales',
        pointLabelFormat: null,
        propertyName: 'sales',
        saleTypeId: 3,
        metricFormatKey: null,
        formatter: null
      }
    ]
  };
  public kpiMonthlyCardConfig: ISalesOverviewKpiConfig = {
    chartTitle: null,
    xAxisPropertyName: 'date',
    metrics: [
      {
        yAxisTitle: 'Sales',
        displayName: 'New Sales',
        pointLabelFormat: null,
        propertyName: 'sales',
        saleTypeId: 1,
        metricFormatKey: null,
        formatter: null
      },
      {
        yAxisTitle: 'Sales',
        displayName: 'CPO Sales',
        pointLabelFormat: null,
        propertyName: 'sales',
        saleTypeId: 3,
        metricFormatKey: null,
        formatter: null
      }
    ]
  };
  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Total Sales
    {
      show: true,
      header: 'Total Sales',
      columnDef: 'totalSales',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Total Sales',
      columnDef: 'totalSalesPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Sales MOM',
      columnDef: 'totalSalesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Total Sales',
      columnDef: 'totalSalesPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Sales YOY',
      columnDef: 'totalSalesYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Lead Sales
    {
      show: true,
      header: 'Sales From Leads',
      columnDef: 'leadSales',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Sales From Leads',
      columnDef: 'leadSalesPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Sales From Leads MOM',
      columnDef: 'leadSalesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Sales From Leads',
      columnDef: 'leadSalesPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Sales From Leads YOY',
      columnDef: 'leadSalesYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Lead Contribution
    {
      show: true,
      header: 'Lead Contr. Rate',
      columnDef: 'leadContribution',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Lead Contr. Rate',
      columnDef: 'leadContributionPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Lead Contr. Rate MOM',
      columnDef: 'leadContributionMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Lead Contr. Rate',
      columnDef: 'leadContributionPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Lead Contr. Rate YOY',
      columnDef: 'leadContributionYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Average Time To Sale
    {
      show: true,
      header: 'Avg. Time To Sale',
      columnDef: 'averageTimeToSale',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.oneDecimalDaysOrNa, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Avg. Time To Sale',
      columnDef: 'averageTimeToSalePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.oneDecimalDaysOrNa, clickable: false
    },
    {
      show: false,
      header: 'Avg. Time To Sale MOM',
      columnDef: 'averageTimeToSaleMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Avg. Time To Sale',
      columnDef: 'averageTimeToSalePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.oneDecimalDaysOrNa, clickable: false
    },
    {
      show: false,
      header: 'Avg. Time To Sale YOY',
      columnDef: 'averageTimeToSaleYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Dealer Avg Lost Sales
    {
      show: true,
      header: 'Dealer Avg. Lost Sales',
      columnDef: 'lostSalesDealerAverage',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.roundToThousandths, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Dealer Avg. Lost Sales',
      columnDef: 'lostSalesDealerAveragePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.roundToThousandths, clickable: false
    },
    {
      show: false,
      header: 'Dealer Avg. Lost Sales MOM',
      columnDef: 'lostSalesDealerAverageMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Dealer Avg. Lost Sales',
      columnDef: 'lostSalesDealerAveragePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.roundToThousandths, clickable: false
    },
    {
      show: false,
      header: 'Dealer Avg. Lost Sales YOY',
      columnDef: 'lostSalesDealerAverageYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

  ];
}
