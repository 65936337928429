import {
    IDigAdScorecardConfig, IDigAdScorecardCategoryConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdScorecardConfig implements IDigAdScorecardConfig {
    public reportTitle = 'Digital Advertising Scorecard';
    public scorecards: IDigAdScorecardCategoryConfig[] = [
        {
            category: 'Awareness',
            billboards: [
                {
                    title: 'Impressions',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'impressions',
                    metricMOMPropertyName: 'impressionsMOM',
                    metricYOYPropertyName: 'impressionsYOY',
                    metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'impressionsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'impressionsMOM',
                    helpTextKey: 'DigAdScorecardImpressionsHelp',
                    helpTextTitle: 'Impressions',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Click Through Rate',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickThroughRate',
                    metricMOMPropertyName: 'clickThroughRateMOM',
                    metricYOYPropertyName: 'clickThroughRateYOY',
                    metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickThroughRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickThroughRateMOM',
                    helpTextKey: 'DigAdScorecardClickThroughRateHelp',
                    helpTextTitle: 'Click Through Rate',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Cost per Impression',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'budgetCostPerImpression',
                    metricMOMPropertyName: 'budgetCostPerImpressionMOM',
                    metricYOYPropertyName: 'budgetCostPerImpressionYOY',
                    metricPreviousMonthPropertyName: 'budgetCostPerImpressionPreviousMonth',
                    metricPreviousMTDPropertyName: 'budgetCostPerImpressionPreviousMTD',
                    metricPreviousYearPropertyName: 'budgetCostPerImpressionPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'budgetCostPerImpressionMOM',
                    helpTextKey: 'DigAdScorecardCostPerImpressionHelp',
                    helpTextTitle: 'Cost per Impression',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Clicks',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clicks',
                    metricMOMPropertyName: 'clicksMOM',
                    metricYOYPropertyName: 'clicksYOY',
                    metricPreviousMonthPropertyName: 'clicksPreviousMonth',
                    metricPreviousMTDPropertyName: 'clicksPreviousMTD',
                    metricPreviousYearPropertyName: 'clicksPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clicksMOM',
                    helpTextKey: 'DigAdScorecardClicksHelp',
                    helpTextTitle: 'Clicks',
                    scoreCardCategory: 'Awareness'
                },
            ],
        },
        {
            category: 'Traffic',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'totalVisits',
                    metricMOMPropertyName: 'totalVisitsMOM',
                    metricYOYPropertyName: 'totalVisitsYOY',
                    metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'totalVisitsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'totalVisitsMOM',
                    helpTextKey: 'DigAdScorecardVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Unique Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'uniqueVisits',
                    metricMOMPropertyName: 'uniqueVisitsMOM',
                    metricYOYPropertyName: 'uniqueVisitsYOY',
                    metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'uniqueVisitsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
                    helpTextKey: 'DigAdScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Page Views',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'pageViews',
                    metricMOMPropertyName: 'pageViewsMOM',
                    metricYOYPropertyName: 'pageViewsYOY',
                    metricPreviousMonthPropertyName: 'pageViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'pageViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'pageViewsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'pageViewsMOM',
                    helpTextKey: 'DigAdScorecardPageViewsHelp',
                    helpTextTitle: 'Page Views',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Cost per Click',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'budgetCostPerClick',
                    metricMOMPropertyName: 'budgetCostPerClickMOM',
                    metricYOYPropertyName: 'budgetCostPerClickYOY',
                    metricPreviousMonthPropertyName: 'budgetCostPerClickPreviousMonth',
                    metricPreviousMTDPropertyName: 'budgetCostPerClickPreviousMTD',
                    metricPreviousYearPropertyName: 'budgetCostPerClickPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'budgetCostPerClickMOM',
                    helpTextKey: 'DigAdScorecardCostPerClickHelp',
                    helpTextTitle: 'Cost Per Click',
                    scoreCardCategory: 'Traffic'
                }
            ]
        },
        {
            category: 'Engagement',
            billboards: [
                {
                    title: 'Time On Site',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'avgTimeOnSite',
                    metricMOMPropertyName: 'avgTimeOnSiteMOM',
                    metricYOYPropertyName: 'avgTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'avgTimeOnSitePreviousMonth',
                    metricPreviousMTDPropertyName: 'avgTimeOnSitePreviousMTD',
                    metricPreviousYearPropertyName: 'avgTimeOnSitePreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    trendArrowDrivingPropertyName: 'avgTimeOnSiteMOM',
                    helpTextKey: 'DigAdScorecardTimeOnSiteHelp',
                    helpTextTitle: 'Time On Site',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'VDP Views',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'vdpViews',
                    metricMOMPropertyName: 'vdpViewsMOM',
                    metricYOYPropertyName: 'vdpViewsYOY',
                    metricPreviousMonthPropertyName: 'vdpViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'vdpViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'vdpViewsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'vdpViewsMOM',
                    helpTextKey: 'DigAdScorecardVdpViewsHelp',
                    helpTextTitle: 'VDP Views',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Engagement Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    helpTextKey: 'DigAdScorecardEngagementRateHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Bounce Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'bounceRate',
                    metricMOMPropertyName: 'bounceRateMOM',
                    metricYOYPropertyName: 'bounceRateYOY',
                    metricPreviousMonthPropertyName: 'bounceRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'bounceRatePreviousMTD',
                    metricPreviousYearPropertyName: 'bounceRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'bounceRateMOM',
                    helpTextKey: 'DigAdScorecardBounceRatesHelp',
                    helpTextTitle: 'Bounce Rate',
                    scoreCardCategory: 'Engagement'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                {
                    title: 'Form Submissions',
                    subtitle: '',
                    iconClass: 'fa-file-text fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    helpTextKey: 'DigAdScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
              },
              {
                title: 'Click To Calls',
                subtitle: '',
                iconClass: 'fa-phone fa-primary',
                reverseMetric: false,
                metricCurrentPropertyName: 'phoneCalls',
                metricMOMPropertyName: 'phoneCallsMOM',
                metricYOYPropertyName: 'phoneCallsYOY',
                metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
                metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
                metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
                metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                footerLeftLabel: 'MOM',
                footerRightLabel: 'YOY',
                footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                trendArrowDrivingPropertyName: 'phoneCallsMOM',
                helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                helpTextTitle: 'Phone Calls',
                scoreCardCategory: 'Actions'
              },
                {
                    title: 'Phone Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'phoneCalls',
                    metricMOMPropertyName: 'phoneCallsMOM',
                    metricYOYPropertyName: 'phoneCallsYOY',
                    metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'phoneCallsMOM',
                    helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                    helpTextTitle: 'Phone Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Action Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    helpTextKey: 'DigAdScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Cost per Conversion',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'budgetCostPerAcquisition',
                    metricMOMPropertyName: 'budgetCostPerAcquisitionMOM',
                    metricYOYPropertyName: 'budgetCostPerAcquisitionYOY',
                    metricPreviousMonthPropertyName: 'budgetCostPerAcquisitionPreviousMonth',
                    metricPreviousMTDPropertyName: 'budgetCostPerAcquisitionPreviousMTD',
                    metricPreviousYearPropertyName: 'budgetCostPerAcquisitionPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'budgetCostPerAcquisitionMOM',
                    helpTextKey: 'DigAdScorecardCostPerConversionHelp',
                    helpTextTitle: 'Cost Per Conversion',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Conversion Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'conversionRate',
                    metricMOMPropertyName: 'conversionRateMOM',
                    metricYOYPropertyName: 'conversionRateYOY',
                    metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'conversionRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'conversionRateMOM',
                    helpTextKey: 'DigAdScorecardConversionRateHelp',
                    helpTextTitle: 'Conversion Rate',
                    scoreCardCategory: 'Actions'
                },
            ]
        }
    ];
}
