import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'logout',
      category: 'logout',
      iconClass: null,
      displayName: null,
      displayInNav: false,
      defaultRoute: '/logout',
      roles: undefined,
      children: [],
      routeData: {
        redirectOnLogout: true,
      }
    },
    {
        id: 'programOverview',
        category: 'programOverview',
        default: false,
        defaultRoute: '/program-overview/program-overview',
        displayName: 'Program Overview',
        displayInNav: false,
        iconClass: 'fa fa-server',
        roles: undefined,
        children: [
            {
                id: "program-overview/program-overview",
                parent: '/program-overview',
                route: 'program-overview',
                displayName: 'Program Overview',
                pdfEnabled: true,
                breadcrumbHelpEnabled: true,
                iconClass: 'fa fa-server',
                roles: undefined
            }]
    },
    {
        id: 'website',
        category: 'website',
        default: true,
        defaultRoute: '/website/overview',
        displayName: 'Website',
        displayInNav: true,
        iconClass: 'fa fa-globe',
        roles: undefined,
        children: [
        {
            parent: '/website',
            route: 'overview',
            displayName: 'Overview',
            pdfEnabled: false,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
        }]
    },
    {
       id: 'digAd',
       category: 'digAd',
       default: false,
       defaultRoute: '/digAd/dashboard',
       displayName: 'Digital Advertising',
       displayInNav: true,
       iconClass: 'fa fa-desktop',
       roles: undefined,
       children: [
         {
           parent: '/digAd',
           route: 'dashboard',
           displayName: 'Digital Advertising Overview',
           pdfEnabled: false,
           iconClass: 'fa fa-users',
           breadcrumbHelpEnabled: true,
           roles: undefined
         }
       ]
     },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: false,
      defaultRoute: '/provider-tools/chat-overview',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [
        // Digital Retailing
        {
          parent: '/provider-tools',
          route: 'digital-retailing',
          displayName: 'Digital Retailing Online Engagement',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        // Trade In
        {
          parent: '/provider-tools',
          route: 'trade-in',
          displayName: 'Trade-In',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-exchange',
          roles: undefined
        },
        // Chat
        {
          parent: '/provider-tools',
          route: 'chat-overview',
          displayName: 'Chat',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        // Service Scheduler
        {
          parent: '/provider-tools',
          route: 'service-scheduler',
          displayName: 'Service Scheduler',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-exchange',
          roles: undefined
        }
      ]
    },
    {
      id: 'user-activity',
      category: 'user-activity',
      default: false,
      defaultRoute: '/user-activity/overview',
      displayName: 'User Activity',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: ['System Administrator', 'Corporate', "System Analyst"],
      children: [
        {
          parent: '/user-activity',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate'],
        }]
    },
    {
      id: 'externalTools',
      category: 'externalTools',
      defaultRoute: '',
      displayName: 'External Tools',
      displayInNav: false,
      iconClass: 'fa fa-external-link',
      roles: undefined,
      externalTool: true,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/externalTools',
          route: 'retailerTools',
          displayName: 'Retailer Tools',
          iconClass: 'fa fa-wrench',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'resources',
      category: 'resources',
      defaultRoute: '/resources/resources',
      displayName: 'Resources',
      displayInNav: true,
      iconClass: 'fa fa-wrench',
      roles: undefined,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/resources',
          route: 'resources',
          displayName: 'Resources',
          iconClass: 'fa fa-wrench',
          roles: undefined,
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"]
        }, {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'filter-configuration',
          displayName: 'Filters',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'filter-configuration/create',
              parent: 'filter-configuration',
              route: 'create',
              displayName: 'Create Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'filter-configuration/update',
              parent: 'filter-configuration',
              route: 'update',
              displayName: 'Edit Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
        }
      ]
    }
  ];
}
