import { IDefaultTableColumnConfig, BillboardConfigMap, Segment  } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import {  IProcessPerformanceConfig } from './process-performance.config.interfaces';

export class ProcessPerformanceConfig implements IProcessPerformanceConfig {

  public summaryTableTitle: string = 'Retailer Summary';

  public metricDisplayModes = [
      { name: 'MOM', value: 'MOM' },
      { name: 'YOY', value: 'YOY' },
      { name: 'Trend', value: 'Trend' }
  ];

  // TODO this isn't really be used and should, all the mapping is explicitly done in the process-report-helper.service
  public billboards: BillboardConfigMap[] = [
    {
        title: 'Lead Volume',
        iconClass: 'fa-users fa-primary',
        metricPropertyName: 'leads',
        metricTrendArrowPropertyName: 'leadsMOM',
        metricMOMPropertyName: 'leadsMOM',
        metricYOYPropertyName: 'leadsYOY',
        metricPreviousMonthPropertyName: 'leadsPreviousMonth',
        metricPreviousMTDPropertyName: 'leadsPreviousMTD',
        metricPreviousYearPropertyName: 'leadsPreviousYear',
        useFooterRowOne: true,
        useFooterRowTwo: true,
        footerTwoLeftLabel: 'MOM',
        footerTwoRightLabel: 'YOY',
        footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
        helpTextKey: 'ProcessPerformanceLeadsBillboardHelp',
        helpTextTitle: 'Process Performance',
        chartConfig: {
          chartType: 'bar',
          metricType: 'Leads',
          metricFormatKey: constants.formatKeys.localeString,
          dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
          brandLabel: 'National',
          districLabel: 'Sub-Area',
          dealerGroupLabel: 'Dealer Group',
          categoryPropertyName: '',
          seriesPropertyName: ''
        }
    },
    {
        title: 'In-Store Conversion',
        iconClass: 'fa-users fa-primary',
        metricPropertyName: 'avgInStoreConversionRate',
        metricTrendArrowPropertyName: 'avgInStoreConversionRateMOM',
        metricMOMPropertyName: 'avgInStoreConversionRateMOM',
        metricYOYPropertyName: 'avgInStoreConversionRateYOY',
        metricPreviousMonthPropertyName: 'avgInStoreConversionRatePreviousMonth',
        metricPreviousMTDPropertyName: 'avgInStoreConversionRatePreviousMTD',
        metricPreviousYearPropertyName: 'avgInStoreConversionRatePreviousYear',
        useFooterRowOne: true,
        useFooterRowTwo: true,
        footerTwoLeftLabel: 'MOM',
        footerTwoRightLabel: 'YOY',
        footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        helpTextKey: 'ProcessPerformanceInStoreConversionRateBillboardHelp',
        helpTextTitle: 'Process Performance In-Store Conversion Rate',
        chartConfig: {
          chartType: 'bar',
          metricType: 'InStoreConversionRate',
          metricFormatKey: constants.formatKeys.percentageOneDecimal,
          dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
          brandLabel: 'National',
          districLabel: 'Sub-Area',
          dealerGroupLabel: 'Dealer Group',
          categoryPropertyName: '',
          seriesPropertyName: ''
        }
    },
    {
        title: 'Close Rate',
        iconClass: 'fa-users fa-primary',
        metricPropertyName: 'avgCloseRate',
        metricTrendArrowPropertyName: 'avgCloseRateMOM',
        metricMOMPropertyName: 'avgCloseRateMOM',
        metricYOYPropertyName: 'avgCloseRateYOY',
        metricPreviousMonthPropertyName: 'avgCloseRatePreviousMonth',
        metricPreviousMTDPropertyName: 'avgCloseRatePreviousMTD',
        metricPreviousYearPropertyName: 'avgCloseRatePreviousYear',
        useFooterRowOne: true,
        useFooterRowTwo: true,
        footerTwoLeftLabel: 'MOM',
        footerTwoRightLabel: 'YOY',
        footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        helpTextKey: 'ProcessPerformanceCloseRateBillboardHelp',
        helpTextTitle: 'Process Performance',
        chartConfig: {
          chartType: 'bar',
          metricType: 'CloseRate',
          metricFormatKey: constants.formatKeys.percentageOneDecimal,
          dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
          brandLabel: 'National',
          districLabel: 'Sub-Area',
          dealerGroupLabel: 'Dealer Group',
          categoryPropertyName: '',
          seriesPropertyName: ''
        }
    },
  ];

  public funnelSegments: Segment[] = [
    {
      name: 'Leads',
      value: 'leads',
      metricFormatKey: constants.formatKeys.localeString,
      colorPropertyName: 'leadsMOM'
    },
    {
      name: 'Response Rate < 30 Min',
      value: 'responseRate30',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName:'responseRate30MOM'
    },
    {
      name: 'Appointments',
      value: 'appointments',
      metricFormatKey: constants.formatKeys.localeString,
      colorPropertyName: 'appointmentsMOM'
    },    
    {
      name: 'Appt. Showed / Test Drive',
      value: 'appointmentShowedOrTestDriveRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName:'appointmentShowedOrTestDriveRateMOM'
    },
    {
      name: 'Offer To Purchase',
      value: 'offersToPurchaseRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName:'offersToPurchaseRateMOM'
    },
    {
      name: 'Close Rate',
      value: 'closeRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName:'closeRateMOM'
    }
  ]

  public dealerTableColumns: IDefaultTableColumnConfig[] = [
      {
          show: true,
          header: '',
          columnDef: 'entity',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: true
          // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
      },
      {
          show: false,
          print: true,
          header: 'Retailer Name',
          columnDef: 'dealerName',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      {
          show: true,
          header: 'Leads',
          columnDef: 'leads',
          isMom: false,
          isYoy: false,
           metricFormatKey: constants.formatKeys.localeString, clickable: false
      }, {
        show: true,
        header: 'Leads Prev. Month',
        columnDef: 'leadsPreviousMTD',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.localeString, clickable: false
      }, {
        show: true,
        header: 'Leads MOM',
        columnDef: 'leadsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate < 30 Min',
        columnDef: 'responseRate30',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate < 30 Min Prev. Month',
        columnDef: 'responseRate30PreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate < 30 Min MOM',
        columnDef: 'responseRate30MOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate',
        columnDef: 'responseRate',
        isMom: false,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate Prev. Month',
        columnDef: 'responseRatePreviousMTD',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Dealer Response Rate MOM',
        columnDef: 'responseRateMOM',
        isMom: true,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Customer Response Rate',
        columnDef: 'customersRepliedRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Customer Response Rate Prev. Month',
        columnDef: 'customersRepliedRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Customer Response Rate MOM',
        columnDef: 'customersRepliedRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Set Rate',
        columnDef: 'appointmentRate',
        isMom: false,
        isYoy: false,
         metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Set Rate Prev. Month',
        columnDef: 'appointmentRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Set Rate MOM',
        columnDef: 'appointmentRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Showed Rate',
        columnDef: 'appointmentsShowedRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Showed Rate Prev. Month',
        columnDef: 'appointmentsShowedRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Appointment Showed Rate MOM',
        columnDef: 'appointmentsShowedRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate',
        columnDef: 'offersToPurchaseRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate Prev. Month',
        columnDef: 'offersToPurchaseRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Offer to Purchase Rate MOM',
        columnDef: 'offersToPurchaseRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate',
        columnDef: 'closeRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate Prev. Month',
        columnDef: 'closeRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'Close Rate MOM',
        columnDef: 'closeRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion Rate',
        columnDef: 'inStoreConversionRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion Rate Prev. Month',
        columnDef: 'inStoreConversionRatePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }, {
        show: true,
        header: 'In-Store Conversion Rate MOM',
        columnDef: 'inStoreConversionRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
      }      
  ];
}
