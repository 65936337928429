import {
  IDefaultTableColumnConfig,
  IDigAdSummaryConfig,
  IDualAxisLineGraphConfig,
  ICardHeaderConfig,
  INameValuePair
} from '../../../../_shared/models/models-index';

import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdSummaryConfig implements IDigAdSummaryConfig {
  public reportTitle = 'Digital Advertising Summary';
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.digAdSummaryHelp,
        helpTextTitle: 'Digital Advertising Summary',
        exportName: 'Digital Advertising Summary - Summary Data',
        metricDisplayModes: ['MOM', 'YOY', 'Trend']
    };

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            // Engagement Rate
            {
                id: Enums.digitalAdvertisingMetrics.engagementRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.engagementRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.engagementRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Action Rate
            {
                id: Enums.digitalAdvertisingMetrics.actionRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.actionRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.actionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Impressions
            {
                id: Enums.digitalAdvertisingMetrics.impressions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.impressions.name,
                propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Clicks
            {
                id: Enums.digitalAdvertisingMetrics.clicks.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clicks.name,
                propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Click Through Rate
            {
                id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Cost Per Click
            {
                id: Enums.digitalAdvertisingMetrics.budgetCostPerClick.metricId,
                displayName: Enums.digitalAdvertisingMetrics.budgetCostPerClick.name,
                propertyName: Enums.digitalAdvertisingMetrics.budgetCostPerClick.nameKey,
                metricFormatKey: constants.formatKeys.currency
            },
            // Cost per Impression
            {
                id: Enums.digitalAdvertisingMetrics.budgetCostPerImpression.metricId,
                displayName: Enums.digitalAdvertisingMetrics.budgetCostPerImpression.name,
                propertyName: Enums.digitalAdvertisingMetrics.budgetCostPerImpression.nameKey,
                metricFormatKey: constants.formatKeys.currency
            },
            // Total Visits
            {
                id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
                displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
                propertyName: Enums.digitalAdvertisingMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Unique Visits
            {
                id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
                displayName: 'Unique Visits',
                propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },

            // Form Submissions
            {
                id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
                propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },

            // Phone Calls
            {
                id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
                displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
                propertyName: Enums.digitalAdvertisingMetrics.phoneCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },

            // Conversion Rate
            {
                id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            // Cost Per Acquisition
            {
                id: Enums.digitalAdvertisingMetrics.budgetCostPerAcquisition.metricId,
                displayName: Enums.digitalAdvertisingMetrics.budgetCostPerAcquisition.name,
                propertyName: Enums.digitalAdvertisingMetrics.budgetCostPerAcquisition.nameKey,
                metricFormatKey: constants.formatKeys.currency
            },
            // Bounce Rate
            {
                id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.digitalAdvertisingMetrics.impressions.metricId,
        defaultMetricTwoId: Enums.digitalAdvertisingMetrics.clicks.metricId,
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };
    public trendTableMetrics: INameValuePair[] = [
      { name: Enums.digitalAdvertisingMetrics.engagementRate.name, value: Enums.digitalAdvertisingMetrics.engagementRate.nameKey },
      { name: Enums.digitalAdvertisingMetrics.actionRate.name, value: Enums.digitalAdvertisingMetrics.actionRate.nameKey },
      { name: Enums.digitalAdvertisingMetrics.impressions.name, value: Enums.digitalAdvertisingMetrics.impressions.nameKey },
      { name: Enums.digitalAdvertisingMetrics.clicks.name, value: Enums.digitalAdvertisingMetrics.clicks.nameKey },
      { name: Enums.digitalAdvertisingMetrics.clickThroughRate.name, value: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey },
      { name: Enums.digitalAdvertisingMetrics.budgetCostPerClick.name, value: Enums.digitalAdvertisingMetrics.budgetCostPerClick.nameKey },
      { name: Enums.digitalAdvertisingMetrics.budgetCostPerImpression.name, value: Enums.digitalAdvertisingMetrics.budgetCostPerImpression.nameKey },
      { name: Enums.digitalAdvertisingMetrics.totalVisits.name, value: Enums.digitalAdvertisingMetrics.totalVisits.nameKey },
      { name: 'Unique Visits', value: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey },
      { name: Enums.digitalAdvertisingMetrics.formSubmissions.name, value: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey },
      { name: Enums.digitalAdvertisingMetrics.phoneCalls.name, value: Enums.digitalAdvertisingMetrics.phoneCalls.nameKey },
      { name: Enums.digitalAdvertisingMetrics.conversionRate.name, value: Enums.digitalAdvertisingMetrics.conversionRate.nameKey },
      { name: Enums.digitalAdvertisingMetrics.budgetCostPerAcquisition.name, value: Enums.digitalAdvertisingMetrics.budgetCostPerAcquisition.nameKey },
      { name: Enums.digitalAdvertisingMetrics.bounceRate.name, value: Enums.digitalAdvertisingMetrics.bounceRate.nameKey },
    ];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },



        // Engagement Rate
        {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Month Engagement Rate',
            columnDef: 'engagementRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Engagement Rate MOM',
            columnDef: 'engagementRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Action Rate
        {
            show: true,
            header: 'Action Rate',
            columnDef: 'actionRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
            show: false,
            header: 'Previous Month Action Rate',
            columnDef: 'actionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Action Rate MOM',
            columnDef: 'actionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Action Rate',
            columnDef: 'actionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Action Rate YOY',
            columnDef: 'actionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Impressions
        {
            show: true,
            header: 'Impressions',
            columnDef: 'impressions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Impressions',
            columnDef: 'impressionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions MOM',
            columnDef: 'impressionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Impressions',
            columnDef: 'impressionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions YOY',
            columnDef: 'impressionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Clicks
        {
            show: true,
            header: 'Clicks',
            columnDef: 'clicks',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Clicks',
            columnDef: 'clicksPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks MOM',
            columnDef: 'clicksMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Clicks',
            columnDef: 'clicksPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks YOY',
            columnDef: 'clicksYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Click Through Rate
        {
            show: true,
            header: 'Click Through Rate',
            columnDef: 'clickThroughRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Click Through Rate',
            columnDef: 'clickThroughRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate MOM',
            columnDef: 'clickThroughRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Click Through Rate',
            columnDef: 'clickThroughRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate YOY',
            columnDef: 'clickThroughRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Cost Per Click
        {
          show: true,
          header: 'Cost Per Click',
          columnDef: 'budgetCostPerClick',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Previous Month Cost Per Click',
          columnDef: 'budgetCostPerClickPreviousMonth',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Click MOM',
          columnDef: 'budgetCostPerClickMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Previous Year Cost Per Click',
          columnDef: 'budgetCostPerClickPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Click YOY',
          columnDef: 'budgetCostPerClickYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Cost Per Impression
        {
          show: true,
          header: 'Cost Per Impression',
          columnDef: 'budgetCostPerImpression',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Previous Month Cost Per Impression',
          columnDef: 'budgetCostPerImpressionPreviousMonth',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Impression MOM',
          columnDef: 'budgetCostPerImpressionMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Previous Year Cost Per Impression',
          columnDef: 'budgetCostPerImpressionPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Impression YOY',
          columnDef: 'budgetCostPerImpressionYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Total Visits
        {
            show: true,
            header: 'Total Visits',
            columnDef: 'totalVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Total Visits',
            columnDef: 'totalVisitsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Visits MOM',
            columnDef: 'totalVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Total Visits',
            columnDef: 'totalVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Visits YOY',
            columnDef: 'totalVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Unique Visitors
        {
            show: true,
            header: 'Unique Visits',
            columnDef: 'uniqueVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Unique Visits',
            columnDef: 'uniqueVisitsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visits MOM',
            columnDef: 'uniqueVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Unique Visits',
            columnDef: 'uniqueVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visits YOY',
            columnDef: 'uniqueVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Form Submissions
        {
            show: true,
            header: 'Form Submissions',
            columnDef: 'formSubmissions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Form Submissions',
            columnDef: 'formSubmissionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions MOM',
            columnDef: 'formSubmissionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Form Submissions',
            columnDef: 'formSubmissionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions YOY',
            columnDef: 'formSubmissionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Phone Calls
        {
            show: true,
            header: 'Phone Calls',
            columnDef: 'phoneCalls',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Phone Calls',
            columnDef: 'phoneCallsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Phone Calls MOM',
            columnDef: 'phoneCallsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Phone Calls',
            columnDef: 'phoneCallsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Phone Calls YOY',
            columnDef: 'phoneCallsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Conversion Rate
        {
            show: true,
            header: 'Conversion Rate',
            columnDef: 'conversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Conversion Rate',
            columnDef: 'conversionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate MOM',
            columnDef: 'conversionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Conversion Rate',
            columnDef: 'conversionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate YOY',
            columnDef: 'conversionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Cost Per Acquisition
        {
          show: true,
          header: 'Cost Per Acquisition',
          columnDef: 'budgetCostPerAcquisition',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Previous Month Cost Per Acquisition',
          columnDef: 'budgetCostPerAcquisitionPreviousMonth',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Acquisition MOM',
          columnDef: 'budgetCostPerAcquisitionMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Previous Year Cost Per Acquisition',
          columnDef: 'budgetCostPerAcquisitionPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
          show: false,
          header: 'Cost Per Acquisition YOY',
          columnDef: 'budgetCostPerAcquisitionYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Bounce Rate
        {
            show: true,
            header: 'Bounce Rate',
            columnDef: 'bounceRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Bounce Rate',
            columnDef: 'bounceRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate MOM',
            columnDef: 'bounceRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Bounce Rate',
            columnDef: 'bounceRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate YOY',
            columnDef: 'bounceRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];
}
