export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'VW-US',
  hostName: '',
  environmentName: 'staging',
  appName: 'VW-US Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '2dd5ff2a-6a4f-4767-9471-7a01cea76e13'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://vwus-analytics-api-v4-staging.azurewebsites.net/api',
  authUri: 'https://vwus-analytics-api-v4-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://vwus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: 'https://vwus-retailertools-web-staging.azurewebsites.net',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/vw-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview','DigitalAdvertisingDashboard']
};
