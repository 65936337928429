import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  orgLookupTypes: Record<string, IOrgLookupType> = {
    'national': { name: 'National', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
    'dealerGroupNationalOrg': { name: 'Dealer Group', orgLookupTypeId: 4, default: false, orgEntityType: 'national' },
    'lma': { name: 'LMA', orgLookupTypeId: 2, default: false, orgEntityType: 'lma' }
  };

  public nationalOrgLookupTypeIds: number[] = [1,4];
  public lmaOrgLookupTypeIds: number[] = [2];
  public virtual20OrgLookupTypeIds: number[] = [3];
  public dealerGroupOrgLookupTypeIds: number[] = [4];

  orgEntityType: Map<string, string> = new Map([
    ['orgcode2', 'orgcode2'],
    ['orgcode1', 'orgcode1'],
    ['dealer', 'dealer'],
  ]);

  entityTypeName: Map<string, string> = new Map([
    ['orgcode2', 'Region'],
    ['orgcode1', 'Area'],
    ['dealer', 'Dealer'],
  ]);
}
